/* eslint-disable react/no-danger */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Section, Wrapper, Background, FixedPanel } from "../content";
import { IntroElem } from "./content";
import { ArrowElement } from "../arrow";
import bgImg from "../../../assets/Avatar.png";
import LadyMobile from "../../../assets/AvatarMobile.png";
import gskLogo from "../../../assets/GSK_Logo_Full_Colour_RGB.png";

import styled from "styled-components";

const HomeWrapper = styled.div`
  width: 100vw;
  height: 700px;
  display: flex;
  align-items: center;
  background: url(${LadyMobile}) no-repeat bottom left #050608;
  background-size: contain;
  flex-direction: column;
  /*  padding-top:3vh; */
  @media (min-width: 600px) {
    height: 100vh;
    background: none;
    position: relative;
    top: 0;
  }
  #home {
    width: 85vw;
    @media (min-width: 600px) {
      width: 65vw;
    }
    @media (min-width: 900px) {
      width: 60vw;
    }
  }

  #contentWrapper {
    width: 85vw;
    display: flex;
    justify-content: flex-end;
  }

  #header {
    display: flex;
    width: 90vw;
    margin-top: 1vh;
    align-items: center;
    justify-content: flex-end;
    /* justify-content:space-between; */
    /*  @media (min-width: 600px) {  
          width:calc(60vw - 100px);
          justify-content: flex-end;
        } */
  }
`;

const HamburgerMenu = styled.div`
  width: 45px;
  height: 27px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
  justify-content: space-between;
  &::before,
  &::after {
    width: 45px;
    height: 4px;
    background: #b8bbbc;
    content: "";
  }
  i {
    width: 45px;
    height: 4px;
    background: #b8bbbc;
    display: inline-block;
  }
`;

const GSKLogo = styled.img`
   width:110px;
   @media (min-width: 600px) {
    margin-left:30px; 
    width:150px;
   }
}
`;

const Content = styled.div`
  text-align: center;
  p {
    font-size: 1.1em;
    padding-top: 3vh;
    padding-bottom: 3vh;
    letter-spacing: 1px;
  }
  h1 {
    font-weight: normal;
    font-stretch: semi-condensed;
    font-size: 2.6em;
    line-height: 1.3;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    word-wrap: break-word;
    color: #f1f2f3;
    font-style: italic;
    sup {
      font-size: 0.4em;
      @media (min-width: 600px) {
        font-size: 0.6em;
      }
    }
    @media (min-width: 600px) {
      font-size: 4.7vw;
      br {
        display: block;
      }
    }
  }
  h2 {
    font-size: 2em;
    letter-spacing: 2px;
    padding-top: 3vh;
    font-style: italic;
    &::after {
      width: 40px;
      height: 5px;
      background: #b2b4b5;
      content: "";
      display: flex;
      flex-direction: row;
      margin: 0px auto;
      margin-top: 4vh;
      border-radius: 5px;
    }
    @media (min-width: 600px) {
      br {
        display: none;
      }
    }
  }
  h4 {
    margin-top: 5vh;
    font-weight: normal;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-weight: 500;
    font-stretch: semi-condensed;
    font-style: normal;
    line-height: 1.5em;
    @media (min-width: 600px) {
      font-size: 1.3em;
    }
    br {
      display: none;
      @media (min-width: 600px) {
        display: block;
        font-size: 1.5em;
      }
    }
  }
  .intro-arrow {
    margin-top: 12vh;
    @media (min-width: 600px) {
      margin-top: 6vh;
    }
  }
`;

const HamburgerMenuList = styled.div`
  width: 250px;
  height: 100vh;
  position: absolute;
  z-index: 9999999;
  background: linear-gradient(#ab5b32, #5e3420);
  margin-left: -260px;
  transition: 0.7s;
  box-shadow: 5px 5px 15px #000;
  &.active {
    margin-left: 0;
  }
`;

export const Intro = () => {
  const { t } = useTranslation("common");

  const [slideMenu, setSlideMenu] = useState(false);

  const onSlideMenu = () => {
    setSlideMenu(!slideMenu);
  };

  return (
    <Section id="section-intro" className="section-intro">
      <HamburgerMenuList className={slideMenu ? "active" : ""}>
        <p onClick={() => onSlideMenu()}>Close</p>
      </HamburgerMenuList>
      <Background
        src={bgImg}
        className="desktop-img"
        alt="woman with shingles rash"
        title="woman with shingles rash"
      />
      {/* <Background src={bgImgMobile}  className="mobile-img"alt={t('content.sections.secIntro.img.copy')} title = {t('content.sections.secIntro.img.copy')}  />    */}
      <HomeWrapper>
        <div id="header">
          {/* <HamburgerMenu onClick={()=>onSlideMenu()}> 
                    <i></i>
                </HamburgerMenu> */}
          <GSKLogo src={gskLogo} alt="gsk" title="gsk" />
        </div>
        <div id="contentWrapper">
          <div id="home">
            <Content>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("content.sections.secIntro.disclaimer.copy1"),
                }}
              />
              <h1
                dangerouslySetInnerHTML={{
                  __html: t("content.sections.secIntro.header.copy1"),
                }}
              />

              {/* <h2>
                DON'T LET <strong>SHINGLES</strong>
                <br /> AFFECT LIFE'S PLANS.
              </h2> */}
              <h4
                dangerouslySetInnerHTML={{
                  __html: t("content.sections.secIntro.sub.copy"),
                }}
              />
              <div
                className="intro-arrow"
                title={t("content.sections.secIntro.arrow.copy")}
              >
                <a href="#" onClick={(event) => event.preventDefault()}>
                  <ArrowElement section="section-one" />
                </a>
              </div>
            </Content>
          </div>
        </div>
      </HomeWrapper>
    </Section>
  );
};
