import styled from 'styled-components';

export const FixedPanel = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  opacity: 0;
  transition: opacity 1s;

  &.intro {
    display: block;
  } 

  &.active {
    opacity: 1; 
    z-index: 4;
  }
`

export const Section = styled.section`
  /* position: relative; */
  width: 100vw;
  background-image: linear-gradient(#222b30, #0a0b0d);
/*   &.section-intro {
    height: calc(100vh);
    @media (min-width: 600px) {
      height: calc(100vh);
    }
  } */

  &.active {
    z-index: 2;
  }

  &#section-one {
    background-image: linear-gradient(#D8D8D8,#D8D8D8,#D8D8D8, #D8D8D8, #b5b6b8, #6b7278);
    video:not(.fixed) {
      top: 0;
      opacity: 1;
      z-index: 2;
    }
  }
  &#section-two{
    background-image: linear-gradient(#6b7278, #1f282d, #112029, #081118, #081118);
    @media (max-width: 600px) {
      margin-top:-1px;
    }
  }
  &#section-five.active {
    video {
      opacity: 1;
    }
  }
`;

export const Background = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0; */
  opacity: 1;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  object-position: center;

 /*  &.active {
    opacity: 1;
  }

  &.fixed {
    position: fixed;
    top: 0;
  } */

  @media (min-width: 600px) {
    height: 100vh;
  }

  &.desktop-img {
    display: none;
    @media (min-width: 600px) {
        display: block;
    }
  }

  &.mobile-img {
    display: block;

    @media (min-width: 600px) {
      display: none;
    }
  }
`;

export const VideoHolder = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 1;
  transform: translateZ(0);

  &.active {
    z-index: 2;
    display: block;
    animation: opacity 1s;
  }

  &.fixed {
    position: fixed;
    top: 0;
  }

  video {
    height: calc(100vh - 59px);
    width: 100vw;
    object-fit: cover;
    object-position: center;

    @media (min-width: 600px) {
      height: calc(100vh - 80px);
    }

    &.desktop-video {
      display: none;

      @media (min-width: 600px) {
          display: block;
      }
    }

    &.mobile-video {
      display: block;

      @media (min-width: 600px) {
        display: none;
      }
    }
  }

  @keyframes opacity {
    from { opacity: 0.5; }
    to   { opacity: 1; }
  }
`;

export const Item = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  position: relative;
  z-index: 2;
  align-items: center;
`;

export const Wrapper = styled.div`
  max-width: 100vw;
  

  &.intro {
    height: calc(100vh - 59px);
    @media (min-width: 600px) {
      height: calc(100vh - 80px);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-end;
     
    }
  }
`;

export const Arrow = styled.span`
  background: url(/arrow.png);
  width: 56px;
  height: 31px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
`

export const MainElem = styled.main`
  
`