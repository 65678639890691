import { useTranslation } from 'react-i18next';
import { Section } from '../content';
import styled from 'styled-components';
import Bg from '../../../assets/bg-btm-section2.jpg';
import AsianMan from '../../../assets/CGI.png';
import chronicLogoDesktop from '../../../assets/thumbnail_chronic-pain-website-logo-white.png';
import chronicLogoMobile from '../../../assets/thumbnail_chronic-pain-website logo.png';

const ImpactOnShingles = styled.div`
 width:100vw;
 
 display:flex;
 flex-direction:column;
 padding-top:0vh;
 @media (min-width: 768px) {
    flex-direction:row;
 };

  #asianMan{
    display:flex;
    align-items: flex-end;
    img{
      width:100%;
      height:auto;
    }
  }
  #content{
     width:calc(100vw - 20px);
     text-transform:uppercase;
     padding:10px;
     text-align:center;
     display:flex;
     align-items:center;
     @media (max-width: 600px) {
        background-color: #D8D8D8;
     }
     @media (min-width: 768px) {
      width:calc(55vw - 20px);
      text-align:left;
     };
     h1{
       font-size:3em;
       color:#FFFFFF;
       font-weight:bold;
       letter-spacing:5px;
       @media (max-width: 600px) {
        color:#151818;
       }
       @media (min-width: 600px) {
        font-size:4em;
       };

       @media (min-width: 768px) {
        font-size:2.2em;
       };
       @media (min-width: 992px) {
        font-size:3em;
       };
       @media (min-width: 1200px) {
        font-size:3.5em;
        br{
          display:none;
        }
       };
     }
     h2{
      font-size:2em;
      color:#FFFFFF;
      letter-spacing:1px;
      padding-top:20px;
      @media (max-width: 600px) {
        color:#151818;
      }
      @media (min-width: 768px) {
        font-size:1.2em;
       };
       @media (min-width: 992px) {
        font-size:2em;
       };
      @media (min-width: 1200px) {
        font-size:2.5em;
        letter-spacing:5px;
        br{display:none}
      };
     }
     h2 + h2{
      border-top:1px solid #a1a1a1;
      margin-top:20px;
     }
     p{
      font-size:1.5em;
      color:#151818;
      padding-top:5vh;
      border-top:1px solid #a1a1a1;
      line-height:1.5em;
      margin-top:5vh;
      @media (min-width: 768px) {
        font-size:1.2em;
       };
       @media (min-width: 992px) {
        font-size:1.3em;
       };
     
     }
     .padTop{
       padding-top:2vh
     }
     .chronic-wrapper{
       border: 2px solid #FFFFFF;
       border-radius: 12px;
       padding: 20px;
       margin-top: 35px;
       @media (max-width: 600px) {
        background-color: #D8D8D8;
        border: 1px solid #000000;
        margin: 45px 10px 15px 10px;
       }
       img.chronic-logo{
          margin-top: -50px;
          background-color: #081118;
          margin-left: 10px;
          width: 175px;
          padding: 0 10px;
          @media (max-width: 600px) {
            background-color: #D8D8D8;
          }
       }
       img.chronic-logo.desktop{
          display:none;
          @media (min-width: 600px) {
            display:block;
          }
       }
       img.chronic-logo.mobile{
        display:none;
        @media (max-width: 600px) {
          display:block;
        }
      }
       p{
        color: #FFFFFF;
        border:0;
        font-style: italic;
        margin-top: 10px;
        padding: 0 20px;
        font-size: 16px;
        @media (max-width: 600px) {
          display:block;
          color: #000000;
          padding: 0 10px;
        }
        span{
          font-style: normal;
          display: inline-block;
          margin-top:10px;
        }
       }
     }
  }
`
export const Section2 = (playedVideos) => {
  const { t } = useTranslation('common');
  return (
    <Section id="section-two">
          <ImpactOnShingles>
              <div id="asianMan">
                     <img src={AsianMan} alt="shingles animation" title="shingles animation" />  
              </div>
              <div id ="content">
                 <div>
                 <h1>SHINGLES <br/> AND  ITS <br/> COMPLICATIONS</h1>
                 <h2>CAN HAVE A Negative impact<br/> on your QUALITY OF LIFE<sup>8</sup></h2>
                 
                 <h2>your mood, sleep, mobility and social life<sup>7,8</sup></h2>
                 <h1 className="padTop">MAY BE <br/> IMPACTED <sup>9,10</sup></h1>
                 </div>
              </div>
          </ImpactOnShingles>
    </Section>
  );
};
