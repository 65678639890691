import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const MenuContainer = styled.div`
 position:fixed;
 top:0;
 z-index:999;
 background:white;
  .menu-active {
    opacity: 1;
    z-index: 9999;
  }
`;

const HamburgerMenu = styled.div`
    margin-top:3vh;
    margin-left:5vw;  
    width:45px;
    height:27px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    cursor:pointer;
    justify-content: space-between;
    position:fixed;
    &::before,&::after{
      width:45px;
      height:4px;
      background:#b8bbbc;
      content:"";
    }
    i{
      width:45px;
      height:4px;
      background:#b8bbbc;
      display:inline-block;
    }
`

const HamburgerMenuList = styled.div`
  width:250px;
  height:100vh;
  position:absolute;
  z-index:9999999;
  background:linear-gradient(#ab5b32, #5e3420);
  margin-left:-260px;
  transition: 0.7s;
  box-shadow: 5px 5px 15px #000;
  &.active{
    margin-left:0;
  }
`

const CloseMenu = styled.div`
   width:100%;
   font-size:1.5em;
   color:#fff;
   text-align:right;
   box-sizing:border-box;
   padding:10px;
   cursor:pointer;
`

const MenuBar = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding:10px;
  font-style:italic;
  border-radius:10px;
  a{
    text-decoration:none;
  }
  strong {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    letter-spacing: 2.55px;
    color: #f1f2f3;
  }
  &:hover, &.active{
    background:linear-gradient(#a55831, #7d4427);
  }
`;


export const Menu = () => {
  const [isOpened, setOpened] = useState(false);
  const [visibleSection, setVisibleSection] = useState(null);
  const [menu, setMenu] = useState(null);
  const [slideMenu, setSlideMenu] = useState(false);

  const { t } = useTranslation('common');
  const sections = ['section-intro','section-one', 'section-two', 'section-three', 'section-four'];

  const isVisible = (section) => {
    const secPos = document.querySelector(`#${section}`)?.getBoundingClientRect();
    const posY = secPos?.y || 0;
    const posH = secPos?.height || 0;
    const isV = posY - (window.innerHeight / 2) <= 0 && (posY - (window.innerHeight / 2)) + posH > 0;
    return isV;
  };

  const scrollIntoView = (id) => {
    setSlideMenu(false)
    document.getElementById(id)?.scrollIntoView({
      behavior: 'smooth',
    });
  };

 
  const renderMenu = () => {
    let menu = [];

    sections.forEach((section, idx) => {
      //console.log(section)
      menu = [...menu, <MenuBar onClick={() => scrollIntoView(section)} key={idx} className={['menuLink'+idx, visibleSection === section ? 'active' : ''].join(' ')}>
         <a href="#" onClick={ (event) => preventLink(event)}><strong>{t(`menu.${section}`)}</strong></a>
        </MenuBar>];
    });

    return menu;
  }
  
  window.onscroll = () => {
    setVisibleSection(null);
    sections.forEach((section) => {
      if (isVisible(section)) {
        setVisibleSection(section);
      }
    });
  };

  useEffect(() => {
    setMenu(renderMenu());
  }, [visibleSection]);


  const onSlideMenu = ()=>{
     setSlideMenu(!slideMenu)
  }

  const preventLink = (event)=>{
    event.preventDefault()
  }


  return (
    <MenuContainer>
        <HamburgerMenuList className={slideMenu ? 'active':''} > 
            <CloseMenu onClick={()=> onSlideMenu()}>X</CloseMenu>
             {menu}
        </HamburgerMenuList>
     <HamburgerMenu onClick={()=> onSlideMenu()} > 
          <i></i>
    </HamburgerMenu>
    </MenuContainer>
  );
};
