import styled from 'styled-components';

export const IntroElem = styled.div`
  margin: 0 auto;
  max-width: 400px;
  height:100vh;
  
  @media (min-width: 600px) {
    min-width: calc(100vw);
    background:red;
    margin: 0;
  }
  .header {
    padding-top: 7vh;
    @media (min-width: 600px) {
      padding-top: 0;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    h1 {
      font-size: 60px;    
      font-stretch: semi-condensed;
      font-style: normal;
      margin: 0;
      line-height: 1.30;
      letter-spacing: 1.75px;
      text-align: center;
      color: #f1f2f3;
      position: relative;
      sup {
        font-size: 14px;
        position:absolute;
        top: 26px;
        @media (max-width: 600px) {
          top: 13px;
        }
    
      @media (min-width: 600px) {
        /* font-size: 110px; */
        letter-spacing: 3.33px;
      }

      
    }
    h3 {
      margin: 5px 32px;
      font-size: 19px;
      font-weight: normal;
      font-stretch: semi-condensed;
      font-style: normal;
      line-height: 1.16;
      letter-spacing: 1.52px;
      text-align: center;
      color: #f1f2f3;
      max-width: 690px;

      strong{
        @media (min-width: 600px) {
          font-size: 110px;
        }
        @media (max-width: 600px) {
          font-size: 70px;
        }
      }

      @media (min-width: 600px) {
        font-size: 35px;
        line-height: 1.13;
        letter-spacing: 2.84px;
        text-align: center;
        color: #f1f2f3;
      }

      
    }
  }

  .sub {
    margin-top: 7vh;
    @media (min-width: 600px) {
      margin-top: 5vh;
      max-width: 800px;
      display: flex;
      justify-content: center;
    }
    h4 {
      font-size: 14px;
      font-weight: 500;
      font-stretch: semi-condensed;
      font-style: normal;
      line-height: 1.11;
      letter-spacing: 0.9px;
      text-transform: initial;
      text-align: center;
      color: #f1f2f3;
      margin: 0;
      @media (max-width: 600px) {
        max-width: 400px;
        margin: 0 auto;
      }
      @media (min-width: 600px) {
        line-height: 25px;
        letter-spacing: 1.28px;
      }
    }
  }

  .intro-arrow {
    margin-top: 5vh;
    max-width: 690px;
    border:1px solid green
    @media (min-width: 600px) {
      max-width: 800px;
      margin-top: 6vh;
      border:1px solid red
    }
  }

  .disclaimer {
    margin-top: 4vh;
    max-width: 690px;
    @media (min-width: 600px) {
      max-width: 800px;
      margin-top: 6vh;
    }
    p {  
      font-size: 11px;
      font-weight: normal;
      font-stretch: semi-condensed;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #f1f2f3;
      text-transform: initial;
      @media (min-width: 600px) {
        font-size: 11px;
      }
    }
  }
`;
