import { Arrow } from './content';

export const ArrowElement = ({section}) => {
  const scrollTo = () => {
    document.getElementById(section)?.scrollIntoView({
      behavior: 'smooth',
    });
  }
  return (
    <Arrow onClick={() => { scrollTo() }} />
  );
};
