import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const References = styled.section`
  padding: 25px 6vw;
  display: flex;
  flex-direction: column;
  position: unset;
  background-color: unset;
  border: none;
  font-family: 'Barlow Semi Condensed', sans-serif;
  word-break: break-word;
  h1{
      font-size: 2.5em;
      color: #151818;
      padding:0px;
      margin:0px;
      text-transform:uppercase;
      letter-spacing: 2px;
  }
  ol{
    padding:0px 0 0 15px;
    margin:0;
  }
  li{
    padding-top:7px;
    font-size:1em; 
    a{
      text-decoration:none;
       &:hover{
          text-decoration:underline;
       }
    }
   }
   p{
      margin-top:30px;
    font-size: 18px;
    }
`;



export const FooterReferences = () => {
  const { t } = useTranslation('common');
  return (
    <References>
      <h1>References</h1>
      <ol>
        <li>Harpaz R, Ortega-Sanchez IR, Seward JF; Advisory Committee on Immunization Practices (ACIP), Centers for Disease Control and Prevention (CDC). Prevention of herpes zoster: recommendations of the Advisory Committee on Immunization Practices (ACIP). MMWR Recomm Rep. 2008 Jun;57(RR-5):1-30.</li>
        <li>Brisson et al. Epidemiology of varicella zoster virus infection in Canada and the United Kingdom. Epidemiol. Infect. (2001), 127, 305±314.</li>
        <li>HSE. Shingles. Available at: Shingles - HSE.ie Accessed: May 2022</li>
        <li>DH Green Book Ch 28a. Shingles. 2016. Available at: www.gov.uk/government/publications/shingles-herpes-zoster-the-green-book-chapter-28a Accessed: May 2022</li>
        <li>Johnson R et al. Herpes zoster epidemiology, management, and disease and economic burden in Europe: A multidisciplinary perspective. Ther Adv Vaccines. 2015; Vol. 3(4) 109–120</li>
        <li>Gidding HF, MacIntyre CR, Burgess MA, Gilbert GL. The seroepidemiology and transmission dynamics of varicella in Australia. Epidemiol Infect. 2003;131(3):1085-9.</li>
        <li>Gauthier A et al. Epidemiology and costs of herpes zoster and postherpetic neuralgia in the United Kingdom. Epidemiol Infect. 2009; 137 38-472.</li>
        <li>NICE Clinical Knowledge Summaries. Shingles: What are the complications. 2021. Available at: https://cks.nice.org.uk/topics/shingles/background-information/complications/ Accessed: May 2022</li>
        <li>Lukas K et al. The impact of herpes zoster and post-herpetic neuralgia on quality of life: patient-reported outcomes in six European countries. J Public Health. 2012; 20:441–451.</li>
        <li>Drolet M et al. The impact of herpes zoster and postherpetic neuralgia on health-related quality of life: a prospective study. CMAJ. 2010; 182(16): 1731–1736.</li>
      </ol>
      <p>If you experience any side effects while taking a medicinal product, talk to your doctor, pharmacist or nurse. This includes any possible side effects not listed in the package leaflet. Adverse events should be reported directly to the Health Products Regulatory Authority (HPRA) on their website: <a href="https://www.hpra.ie/homepage/medicines/safety-information/reporting-suspected-side-effects/" target="_blank">Reporting Suspected Side Effects (hpra.ie)</a></p>
    </References>
  );
};
