import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Banner = styled.section`
  display: flex;
  position: fixed;
  z-index: 100;
  bottom: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  background-color: #172f3c;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-size:14px;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 1.75px;
  text-align: center;
  color: #f1f2f3;
  height: 100px;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;
  p{
    font-weight: normal;
    font-size:11px;
  }
  @media only screen and (max-width: 600px){
    padding: 10px 15px 0 15px;
    span br {
      display: none;
    }
  }
  @media only screen and (min-width: 600px) {
    height: 100px;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 3px;

    p{
      font-size:12px;
      padding:0;
      margin:0;
      text-transform:none;
      
    }
      br {
      display: none;
    }    
  }

  @media only screen and (min-width: 768px) {
    height: 100px;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 3px;

    p{
      font-size:14px;
      padding:0;
      margin:0;
      text-transform:none;
    }
    br {
      display: block;
    }    
  }
  
`;

export const FooterBanner = () => {
  const { t } = useTranslation('common');
  return (
    <Banner id="banner-footer">
      <span
        dangerouslySetInnerHTML={{
          __html: t('footer.banner.copy1'),
        }}>
      </span>
      <p  dangerouslySetInnerHTML={{
          __html: t('footer.banner.copy2'),
        }}></p>
    </Banner>
  );
};
