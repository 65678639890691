import styled from "styled-components";
import gskLogo from "../../assets/GSK_Logo_Full_Colour_RGB.png";
import gskLogoWebp from "../../assets/GSK_Signal_Full_Colour_RGB.webp";
import chronicLogo from "../../assets/thumbnail_chronic-pain-website logo.png";

const Copyright = styled.section`
  background-color: #f8f8f8;
  /* padding: 30px 0 47px 0; */
  margin-bottom: 125px;

  padding: 0 6vw 0 6vw;
  @media only screen and (min-width: 600px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    height: 100px;
    box-sizing: border-box;
    margin-bottom: 100px;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    text-align: center;
  }
  picture {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 600px) {
    }
  }
`;

const CopyrightLogo = styled.img`
  width: 100px;
  // margin: auto;
  display: inline-block;
`;
const ChronicPainLogo = styled.img`
  width: 138px;
  vertical-align: top;
  // margin-top: 10px;
  @media (min-width: 600px) {
    width: 138px;
    vertical-align: top;
    margin: 0;
  }
`;
const CopyrightCopy = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  align-item: center;
  justify-content: center;
  @media only screen and (min-width: 600px) {
    text-align: right;
    width: 84%;
  }
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style-type: none;
    text-transform: uppercase;
    font-size: 11px;
  }
  ul {
    display: flex;
    margin-left: 8vw;
    @media only screen and (min-width: 600px) {
      margin-left: 0vw;
    }
  }
  li {
    padding: 0px 0 0px 0;
    span {
      padding: 0 5px 0 5px;
      border-right: 1px dotted #151818;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &:last-child span {
      border-right: 0;
      padding-right: 0;
    }
  }

  @media only screen and (min-width: 576px) {
    align-items: flex-end;
    font-size: 13.5px;
    ul,
    li {
      font-size: 16px;
    }
  }
`;

export const FooterCopyright = () => {
  return (
    <Copyright>
      <picture alt="gsk" title="gsk">
        {/* <source srcSet={gskLogoWebp} type="image/webp" />
        <source srcSet={gskLogo} type="image/png" /> */}
        <CopyrightLogo src={gskLogo} alt="GSK Logo" />
      </picture>

      <CopyrightCopy>
        <ul>
          <li>
            <span>
              <a
                href="https://terms.gsk.com/en-gb/pharmaceuticals/default/"
                target="_blank"
              >
                Terms of use
              </a>
            </span>
          </li>
          <li>
            <span>
              <a
                href="https://privacy.gsk.com/en-gb/privacy-notice/"
                target="_blank"
              >
                GSk Privacy Statement
              </a>
            </span>
          </li>
          <li>
            <span>
              <a
                href="https://privacy.gsk.com/en-gb/privacy-notice/general/general-full-text/#cookies"
                target="_blank"
              >
                Cookie Policy
              </a>
            </span>
          </li>
        </ul>
        <span>&copy;2022 GSK group of companies or its licensor.</span>
        <span>NP-IE-HZX-WCNT-220001. GlaxoSmithKline IRELAND Limited,</span>
        <span>Registered in IRELAND No. 15513</span>
      </CopyrightCopy>
    </Copyright>
  );
};
