import React, {useState}from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Section } from '../content';
import PersonDesk from '../../../assets/person_icon_white_desk.png';
import VirusDesk from '../../../assets/virus_icon_white_desk.png';
import ImuneDesk from '../../../assets/mask_icon_white_desk.png';
import PersonMob from '../../../assets/person_icon_white_mob.png';
import VirusMob from '../../../assets/virus_icon_white_mob.png';
import ImuneMob from '../../../assets/mask_icon_white_mob.png';
import Tick from '../../../assets/tick.png';

const Risk = styled.div`
   width:100vw;
   background:#23292d;
   padding:7vh 0 7vh 0px; 
   display:flex;
   justify-content:center;
   #wrapper{
      width:90vw;
      @media (min-width: 992px) {
        width:950px;
      }
      h1{
        color:#f1f2f3;
        text-transform:uppercase;
        font-size:4em;
        text-align:center;
        letter-spacing:3px;
      }
      h2{
        color:#f1f2f3;
        text-transform:uppercase;
        font-size:1.5em;
        text-align:center;
        letter-spacing:4px;
        padding:20px 0 50px 0;
        font-weight: normal;
        br{
         display:none;
        }
        @media (min-width: 768px) {
          font-size:2em;
          br{
            display:block;
           }
        }
      }
      h3{
        color:#f1f2f3;
        text-transform:uppercase;
        font-size:1.5em;
        text-align:center;
      }
      p{
        text-align:center;
      }
      #cardWrapper{
        width:90vw;
        display:flex;
        align-items:center;
        flex-direction:column;
        @media (min-width: 992px) {
          flex-direction:row;
          width:940px;
          justify-content:space-between;
        }
        .card{
          width:280px;
          height:260px;
          position: relative;
          transition: transform 0.8s;
          transform-style: preserve-3d;
          cursor:pointer;
          padding-bottom:30px;
          @media (min-width: 600px) {
            height:415px;
            padding-bottom:30px;
          }
          .cardFront,.cardBack{
            position: absolute;
            -webkit-backface-visibility: hidden; /* Safari */
            backface-visibility: hidden;
            width:280px;
            height:260px;
            padding:15px;
            box-shadow: 5px 10px 10px #000;
            box-sizing: border-box;
            border-radius:15px;
            display:flex;
            flex-direction:column;
            justify-content:space-between;
            align-items:center;
            br{
              display:none
            }
            @media (min-width: 600px) {
              height:415px;
              padding:25px;
              br{
                display:block;
              }
            }
          }
          .cardFront{
             &.person{
              background: url(${PersonMob}) center center no-repeat, linear-gradient(#ab5b32, #5e3420);
            }
             &.virus{
              background: url(${VirusMob}) center center no-repeat, linear-gradient(#ab5b32, #5e3420);
             }
             &.imune{
              background: url(${ImuneMob}) center center no-repeat, linear-gradient(#ab5b32, #5e3420);
             }
            @media (min-width: 600px) {
               &.person{
                background: url(${PersonDesk}) center center no-repeat, linear-gradient(#ab5b32, #5e3420);
              }
               &.virus{
                background: url(${VirusDesk}) center center no-repeat, linear-gradient(#ab5b32, #5e3420);
               }
               &.imune{
                background: url(${ImuneDesk}) center center no-repeat, linear-gradient(#ab5b32, #5e3420);
               }
            }
            
          }
          .cardBack{
             background:url(${Tick}) no-repeat center 15px,linear-gradient(#4f585a, #2a2f31);
             padding-top:70px;
             transform: rotateY(180deg);
             h3{
              font-size:1.5em;
               @media (min-width: 600px) {
                  font-size:1.8em;
                   height:90px;
               }
             }

             p{
               font-size:1em;
               @media (min-width: 600px) {
                font-size:1.2em;
               
             }
             }
          }
        }

        .flipCard{
          transform: rotateY(180deg);
        }
        
      }
     
   }
   
`

const Icon = styled.div`
        width:32px;
        height:32px;
        background:#fff;
        color:#5d341f;
        border-radius:20px;
        font-weight:normal;
        font-weight:bold;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        cursor:pointer;
        &::before{
          content:"";
          width:16px;
          height:2px;
          background:#5d341f;
        }
        &::after{
          content:"";
          width:16px;
          height:2px;
          background:#5d341f;
          margin-top:-2px;
          transform: rotate(90deg);
        }
        @media (min-width: 600px) {
          width:42px;
          height:42px;
          &::before{  
            width:16px;
          }
          &::after{
            width:16px;
          }
        }
       
        &.minus{
          &::before{
            background:#484c4e;
          }
          &::after{
            display:none;
          }
        }
        
      `

export const Section3 = (playedVideos) => {
  const { t } = useTranslation('common');
  const [flipCardState, setFlipCardState] = useState({
     person:false,
     virus:false,
     imune:false
  })

  const onFlipCard = (value)=>{
      const muteCardState = {...flipCardState};
      muteCardState[value] = !muteCardState[value];
      setFlipCardState(muteCardState);
  }
   
  return (
    <Section id="section-three">
      <Risk>
        <div id="wrapper">
          <h1>BE <strong>INFORMED</strong></h1>
          <h2>THINK YOU MIGHT BE AT RISK? </h2>
          <div id="cardWrapper">
            <div 
              onClick ={() => onFlipCard('person')} 
              className={flipCardState.person ? 'card dtflipcardclick flipCard':'card dtflipcardclick'}
              data-flipcardname = "I'm an adult aged 50 years of age or older" data-flipcardstatus = {flipCardState.person ? 'open' :'close'} >
              <div className="cardFront person">
                <h3>50 YEARS OF AGE <br/> OR OLDER?</h3>
                <Icon />
              </div>
              <div className="cardBack">
                <h3>I'm 50 YEARS OF AGE <br/> OR OLDER?</h3>
                <p>The incidence of shingles increases with age, particularly in adults 50 years of age or older.<sup>5,7</sup></p>
                <Icon className="minus" />
              </div>
            </div>
            <div 
             onClick ={() => onFlipCard('virus')} 
             className={flipCardState.virus ? 'card dtflipcardclick flipCard':'card dtflipcardclick'}
             data-flipcardname = "I've had chickenpox" data-flipcardstatus = {flipCardState.virus ? 'open' :'close'} >
              <div className="cardFront virus">
                <h3>I've had <br /> chickenpox</h3>
                <Icon  />
              </div>
              <div className="cardBack">
                <h3>I’ve had <br /> chickenpox</h3>
                <p>Anyone who has had chickenpox is at risk of developing shingles, with an estimated lifetime risk of 1 in 3.<sup>1,2</sup></p>
                
                <Icon className="minus"  />
              </div>
            </div>
            <div 
            onClick ={() => onFlipCard('imune')} 
            className={flipCardState.imune ? 'card dtflipcardclick flipCard':'card dtflipcardclick'}
            data-flipcardname = "I have a weakened immune system" data-flipcardstatus = {flipCardState.imune ? 'open' :'close'}
            >
              <div className="cardFront imune">
                <h3>I HAVE A WEAKENED IMMUNE SYSTEM</h3>
                <Icon  />
              </div>
              <div className="cardBack">
                <h3>I HAVE A WEAKENED IMMUNE SYSTEM</h3>
                <p>People who have a weakened immune system are at an increased risk of developing shingles and more likely to have a more severe case.<sup>4</sup></p>
                <Icon className="minus" />
              </div>
            </div>
          </div>
          <h2><strong>TALK TO YOUR PHARMACIST, PRACTICE NURSE <br/>OR DOCTOR for more information.</strong></h2>
          <p>This information is not intended to be a substitute for professional medical advice. </p>
        </div>
      </Risk>
    </Section>
  );
};
