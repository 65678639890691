import styled from 'styled-components';
import { FooterBanner } from './Banner';
import { FooterCopyright } from './Copyright';
import { FooterReferences } from './References';

const FooterElem = styled.footer`
  position: relative;
  z-index: 20;
  background: #fff;
`;

export const Footer = () => {
  return (
    <FooterElem>
      <FooterReferences />
      <FooterCopyright />
      <FooterBanner />
    </FooterElem>
  );
};
