import { useEffect, useState } from 'react';
import { Intro } from './intro';
import { Section1 } from './section1';
import { Section2 } from './section2';
import { Section3 } from './section3';
import { Section4 } from './section4';
import { MainElem } from './content';

export const Sections = (sectionActive) => {

  return (
    <MainElem>
      <Intro />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      
    </MainElem>
  );
};
