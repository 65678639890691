// eslint-disable-next-line no-use-before-define
import React from 'react';
import ReactDOM from 'react-dom';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { App } from './App';
import commonEn from './translations/commonEn.json';

window.localStorage.setItem('activeVideo', '')

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: { common: commonEn },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
